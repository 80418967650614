<template>
  <div>
    <a-modal
      v-model="visibleParentSuggestion"
      :footer="null"
      :mask="false"
      :height="500"
      :dialog-style="{
        top: '20px',
      }"
      :ok-button-props="{ props: { disabled: true } }"
      :cancel-button-props="{ props: { disabled: true } }"
    >
      <label class="typo__label">{{
        $t("profil.parentSuggestionTitle")
      }}</label>
      <multiselect
        v-model="selectedSuggestionParent"
        :placeholder="$t('recette.selectionnerParent')"
        :selectLabel="$t('sms.pressEnterToSelect')"
        :options="suggestionParentList"
        :custom-label="
          ({ name }) => {
            return name;
          }
        "
        :close-on-select="false"
        :preserve-search="true"
        :maxHeight="700"
        label="_id"
        track-by="_id"
        :preselect-first="true"
        :multiple="false"
      >
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="align-text-top">
              {{ props.option.name }}
            </span>
            <br />
            <span class="align-text-top">
              {{ $t("profil.tel") }}: {{ props.option.fatherPhone }} -
              {{ props.option.motherPhone }}
            </span>
            <br />
            <span class="option__small font-size-12"
              >{{ $t("inscription.enfant") }} :
              {{ props.option.children }}</span
            >
          </div>
        </template>

        <template slot="noOptions">
          {{ $t("sms.noOptions") }}
        </template>
      </multiselect>
      <a-divider class="mt-3 mb-2"></a-divider>
      <div style="margin-bottom: 30px">
        <a-button
          @click="visibleParentSuggestion = false"
          class="ml-2 mr-2"
          style="float: right"
          >{{ $t("all.cancel") }}</a-button
        >
        <a-button
          @click="handleAddParentSuggestion"
          :disabled="!selectedSuggestionParent"
          type="primary"
          style="float: right"
          >{{ $t("all.add") }}</a-button
        >
      </div>
    </a-modal>
    <a-modal
      v-model="visibleDeleteReasson"
      v-show="visibleDeleteReasson"
      :title="$t('all.enterInformationBelow')"
      :width="500"
    >
      <div class="row">
        <div class="col-lg-12">
          <a-form-item :label="$t('punition.raison')">
            <a-input
              :placeholder="$t('punition.raison')"
              type="textarea"
              v-model="deleteReason"
            />
          </a-form-item>
        </div>
      </div>
      <template slot="footer">
        <a-button key="back" @click="visibleDeleteReasson = false">
          {{ $t("action.annuler") }}
        </a-button>
        <a-button key="submit" type="primary" @click="onDelete">
          {{ $t("all.okay") }}
        </a-button>
      </template>
    </a-modal>

    <a-page-header
      :title="$t('liste.listeEleves')"
      :sub-title="$t('liste.consulterProfil')"
    >
      <template slot="tags">
        <a-tag color="blue"> {{ $t("liste.administration") }} </a-tag>
      </template>
      <template slot="extra">
        <a-button type="primary" @click="showModal" key="2">
          <a-icon type="user-add" @click="showModal" />{{
            $t("liste.ajouterEleve")
          }}
        </a-button>
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item
              key="1"
              @click="generateBadges"
              :disabled="loadingBadges"
              :loading="loadingBadges"
            >
              <a-icon type="file-pdf" @click="generateBadges" />{{
                $t("action.imprimerBadges")
              }}
            </a-menu-item>
            <a-menu-item
              key="5"
              @click="generateNewBadges"
              :disabled="loadingBadges"
              :loading="loadingBadges"
            >
              <a-icon type="file-pdf" @click="generateNewBadges" />{{
                $t("action.imprimerNewBadges")
              }}
            </a-menu-item>
            <a-menu-item key="2" @click="generatePdf">
              <a-icon type="file-pdf" @click="generatePdf" />
              {{ $t("action.imprimerTab") }}
            </a-menu-item>
            <a-menu-item key="3" @click="openImport">
              <a-icon type="file-excel" @click="openImport" />
              {{ $t("action.importer") }}
            </a-menu-item>
            <a-menu-item key="4" @click="openArchive">
              <a-icon type="database" @click="openArchive" />
              {{ $t("liste.listeElevesArchive") }}
            </a-menu-item>
            <a-menu-item key="6" @click="generateLoginsParents">
              <a-icon
                type="file-pdf"
                @click="loadingBadges"
                :disabled="loadingBadges"
                :loading="loadingBadges"
              />
              Logins parents
            </a-menu-item>
          </a-menu>
          <a-button
            style="margin-left: 8px"
            :disabled="loadingBadges"
            :loading="loadingBadges"
          >
            {{ $t("action.actions") }} <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </template>
      <a-descriptions size="small" :column="3">
        <a-descriptions-item :label="$t('liste.nonInscrits')">
          {{ headerInscrit }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('liste.inscrits')">
          {{ headerNonInscrit }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('liste.parents')">
          {{ parentsCount }}
        </a-descriptions-item>
      </a-descriptions>
      <a-row type="flex">
        <a-statistic :title="$t('liste.total')" :value="filtredTable.length" />
        <a-statistic
          :title="$t('profil.fille')"
          prefix="%"
          :value="countFille"
          :style="{
            margin: '0 32px',
          }"
        />
        <a-statistic
          :title="$t('profil.garcon')"
          prefix="%"
          :value="countGarcon"
        />
      </a-row>
    </a-page-header>
    <div>
      <a-modal
        v-model="visibleData"
        v-show="visibleData"
        :title="$t('all.enterInformationBelow')"
        on-ok="addStudent"
        :width="1000"
      >
        <template slot="footer">
          <a-button
            key="back"
            :disabled="loadingData"
            @click="handleCancelModalAdd"
          >
            {{ $t("action.annuler") }}
          </a-button>
          <a-button
            key="submit"
            type="primary"
            :disabled="loadingData"
            @click="addStudent"
          >
            {{ $t("action.confirmer") }}
          </a-button>
        </template>
        <a-form :form="newStudentForm" class="container" @submit="addStudent">
          <div class="row">
            <div class="col-lg-4">
              <a-form-item :label="$t('liste.identifiantUnique')">
                <a-input
                  @change="debounceField"
                  :placeholder="
                    $t('liste.identifiantUnique') + ' (المعرف الوحيد)'
                  "
                  v-decorator="[
                    'uniqueId',
                    {
                      trigger: ``,
                    },
                  ]"
                />
              </a-form-item>
            </div>
            <div class="col-lg-4">
              <a-form-item :label="$t('profil.nom')">
                <a-input
                  @change="debounceField"
                  :placeholder="$t('profil.nom')"
                  v-decorator="[
                    'lastName',
                    {
                      trigger: ``,
                      rules: [
                        {
                          required: true,
                          message: $t('requis.nom'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
            <div class="col-lg-4">
              <a-form-item :label="$t('profil.prenom')">
                <a-input
                  @change="debounceField"
                  :placeholder="$t('profil.prenom')"
                  v-decorator="[
                    'firstName',
                    {
                      trigger: ``,
                      rules: [
                        {
                          required: true,
                          message: $t('requis.prenomPere'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <a-form-item :label="$t('profil.dateNaissance')">
                <a-date-picker
                  :placeholder="$t('profil.dateNaissance')"
                  format="DD-MM-YYYY"
                  :disabled-date="disabledDate"
                  v-decorator="[
                    'BirthDate',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.dateNaissance'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item :label="$t('profil.sexe')">
                <a-radio-group
                  :placeholder="$t('profil.sexe')"
                  v-decorator="[
                    'gender',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.genre'),
                        },
                      ],
                    },
                  ]"
                >
                  <a-radio value="male"> {{ $t("profil.garcon") }}</a-radio>
                  <a-radio value="female"> {{ $t("profil.fille") }} </a-radio>
                </a-radio-group>
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <a-form-item :label="$t('profil.adresse')">
                <a-input
                  :placeholder="$t('profil.adresse')"
                  @change="debounceField"
                  v-decorator="['adress', { trigger: `` }]"
                />
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item :label="$t('liste.telephone')">
                <a-input
                  :placeholder="$t('liste.telephone')"
                  type="number"
                  @change="debounceField"
                  v-decorator="[
                    'phone',
                    {
                      trigger: ``,
                      rules: [
                        {
                          len: 8,
                          message: $t('requis.telephone'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <a-form-item :label="$t('profil.notes')">
                <a-input
                  :placeholder="$t('profil.notes')"
                  type="textarea"
                  @change="debounceField"
                  v-decorator="[
                    'notes',
                    {
                      trigger: ``,
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <a-form-item :label="$t('liste.classes')">
                <a-select
                  show-search
                  :placeholder="$t('liste.classes')"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="
                    (val) => {
                      selectedClassRoom = classRoomList.find(
                        (item) => item.id === val
                      );
                    }
                  "
                  v-decorator="[
                    'classRoom',
                    {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    },
                  ]"
                >
                  >
                  <a-select-option
                    :value="item.id"
                    v-for="item in classRoomList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-8 mt-4">
              <a-tag color="green" class="mb-1 mt-4">
                {{ $t("inscription.fraisInscri") }} :
                {{
                  selectedClassRoom ? selectedClassRoom.inscription : "--"
                }}</a-tag
              >
              <a-tag color="green" class="mb-1 mt-4">
                {{ $t("inscription.fraisMensuel") }} :
                {{
                  selectedClassRoom ? selectedClassRoom.monthly : "--"
                }}</a-tag
              >
            </div>
          </div>

          <div class="row" v-if="selectedClassRoom">
            <div class="col-lg-3">
              <h6 for="">{{ $t("inscription.fraisInscri") + " (dt)" }}</h6>
              <p>{{ selectedClassRoom.inscription }}</p>
            </div>
            <div
              @click="changeModeP('M')"
              :class="[
                'col-lg-4',
                'mr-2',
                'pt-2',
                modePaiement === 'M' ? 'custom-border-active' : 'custom-border',
              ]"
            >
              <h6 for="">{{ $t("inscription.fraisMensuel") + " (dt)" }}</h6>
              <p>{{ selectedClassRoom.monthly }}</p>
            </div>
            <div
              @click="changeModeP('T')"
              :class="[
                'col-lg-4',
                'pt-2',
                modePaiement === 'T' ? 'custom-border-active' : 'custom-border',
              ]"
              v-if="selectedClassRoom.tranches"
            >
              <h6 for="">Frais des tranches (dt)</h6>
              <li
                v-for="t in selectedClassRoom.tranches"
                :key="t.trancheNumber"
              >
                {{ "Tranche " + t.trancheNumber + " : " + t.amount }}
              </li>
            </div>
            <a-alert
              class="mt-2"
              :message="
                'Le mode de paiment selectionné est ' +
                (modePaiement === 'T' ? 'par tranches' : 'mensuelle')
              "
              type="info"
              show-icon
            />
            <a-divider />
          </div>

          <div class="row">
            <div class="col-md-5">
              <a-form-item :label="$t('liste.parentsExis')">
                <a-select
                  :disabled="disabledSP"
                  show-search
                  :autoFocus="true"
                  :placeholder="$t('liste.parentsExis')"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-decorator="[
                    'parent',
                    {
                      rules: [
                        {
                          required: !newParent,
                          message: $t('requis.selection'),
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in parentsList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item :label="$t('liste.ajouterParent')">
                <a-switch @change="triggerAddNewParent" v-model="newParent">
                  <a-icon slot="checkedChildren" type="usergroup-add" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>
              </a-form-item>
            </div>
          </div>
          <a-card
            :title="$t('liste.ficheParents')"
            :bordered="true"
            class="span container"
            v-if="newParent"
          >
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.nomPere')">
                  <a-input
                    :placeholder="$t('liste.nomPere')"
                    @change="debounceField"
                    type="text"
                    v-decorator="[
                      'parentdata.father.firstName',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.nomPere'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.prenomPere')">
                  <a-input
                    @change="debounceField"
                    :placeholder="$t('liste.prenomPere')"
                    type="text"
                    v-decorator="[
                      'parentdata.father.lastName',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.prenomPere'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.nomMere')">
                  <a-input
                    @change="debounceField"
                    :placeholder="$t('liste.nomMere')"
                    type="text"
                    v-decorator="[
                      'parentdata.mother.firstName',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.nomMere'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.prenomMere')">
                  <a-input
                    @change="debounceField"
                    :placeholder="$t('liste.prenomMere')"
                    type="text"
                    v-decorator="[
                      'parentdata.mother.lastName',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.prenomMere'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.telPere')">
                  <a-input
                    :placeholder="$t('liste.telPere')"
                    type="number"
                    @change="
                      (val) => {
                        handleParentsSuggestions(val);
                        debounceField(val);
                      }
                    "
                    v-decorator="[
                      'parentdata.father.phone',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.telPere'),
                          },
                          {
                            len: 8,
                            message: $t('requis.telephone'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.telMere')">
                  <a-input
                    @change="
                      (val) => {
                        handleParentsSuggestions(val);
                        debounceField(val);
                      }
                    "
                    type="number"
                    :placeholder="$t('liste.telMere')"
                    v-decorator="[
                      'parentdata.mother.phone',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.telMere'),
                          },
                          {
                            len: 8,
                            message: $t('requis.telephone'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <!--parents job-->
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.metierPere')">
                  <a-input
                    :placeholder="$t('liste.metierPere')"
                    @change="debounceField"
                    v-decorator="[
                      'parentdata.father.job',
                      {
                        trigger: ``,
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.metierMere')">
                  <a-input
                    @change="debounceField"
                    :placeholder="$t('liste.metierMere')"
                    v-decorator="[
                      'parentdata.mother.job',
                      {
                        trigger: ``,
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.adresseParents')">
                  <a-input
                    :placeholder="$t('liste.adresseParents')"
                    type="text"
                    @change="debounceField"
                    v-decorator="[
                      'parentdata.adress',
                      {
                        trigger: ``,
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.emailParents')">
                  <a-input
                    :placeholder="$t('liste.emailParents')"
                    type="text"
                    @change="debounceField"
                    v-decorator="[
                      'parentdata.email',
                      {
                        trigger: ``,
                        rules: [
                          {
                            type: 'email',
                            message: $t('requis.email'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
          </a-card>
        </a-form>
      </a-modal>
    </div>
    <div>
      <a-table
        @change="tableChanged"
        :loading="tableLeading"
        :pagination="true"
        :data-source="data"
        :columns="columns"
        :rowKey="'_id'"
      >
        <template slot="firstName" slot-scope="text, record">
          <div key="firstName" @click="() => openProfile(record.id)">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="(e) => handleChange(e.target.value, record.id, col)"
            />
            <template v-else>
              <a-avatar
                size="large"
                v-if="record.photo"
                :src="settings.base_url + '/' + record.photo"
              />
              <a-avatar
                size="large"
                style="color: #f56a00; background-color: #fde3cf"
                v-else
              >
                {{ record.firstName[0].toUpperCase() }}
              </a-avatar>
              <a-button size="small" type="link" class="ml-2">
                {{ text + " " + record.lastName }}
              </a-button>
            </template>
          </div>
        </template>
        <template
          v-for="col in ['attestationPresence']"
          :slot="col"
          slot-scope="text, record"
        >
          <div :key="col">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="(e) => handleChange(e.target.value, record.id, col)"
            />
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <span slot="scolarite" slot-scope="text">
          <a-tag
            :key="text"
            :color="
              text === 'NON INSCRIT' || text === 'غير مسجل'
                ? 'volcano'
                : 'green'
            "
          >
            {{ text.toUpperCase() }}
          </a-tag>
        </span>
        <a-button
          slot="attestationPresence"
          slot-scope="text, record"
          :disabled="
            record.scolarite === 'NON INSCRIT' ||
            record.scolarite === 'غير مسجل'
          "
          @click="
            () => {
              attestationPresence(record);
            }
          "
        >
          <a-icon type="file-pdf" /> {{ $t("action.imprimer") }}
        </a-button>
        <a-button
          slot="inscription"
          slot-scope="text, record"
          @click="() => attestationInscriptionPDF(record)"
        >
          <a-icon type="file-pdf" /> {{ $t("action.imprimer") }}
        </a-button>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
        <template slot="payment" slot-scope="text, record">
          <MultiPaymentValidater
            :idParent="record.parent[0]._id"
            :student="record"
            @reload="reload"
            :classroomName="record.scolarite ? record.scolarite : ''"
          />
        </template>
        <template slot="delete" slot-scope="text, record">
          <a-button-group>
            <a-popconfirm
              :title="$t('requis.supp2')"
              @confirm="() => deleteStudent(record.id)"
              v-if="verifyAccess(['all', 'suppression_eleve'])"
            >
              <a-button type="danger" size="small" icon="delete">
                {{ $t("action.supprimer") }}
              </a-button>
            </a-popconfirm>
            <a-button
              v-else
              type="danger"
              :disabled="true"
              size="small"
              icon="delete"
            >
              {{ $t("action.supprimer") }}
            </a-button>
            <a-popconfirm
              :title="$t('requis.annu')"
              @confirm="() => onCancelInscription(record.id)"
              v-if="
                verifyAccess(['all', 'annulation_eleve']) &&
                record.scolarite.toLowerCase() !== 'non inscrit'
              "
            >
              <a-button type="danger" size="small" icon="user-delete">
                {{ $t("action.annuler") }}
              </a-button>
            </a-popconfirm>
            <a-button
              v-else
              type="danger"
              size="small"
              icon="user-delete"
              :disabled="true"
            >
              {{ $t("action.annuler") }}
            </a-button>
          </a-button-group>
        </template>
      </a-table>
    </div>
    <a-modal
      v-model="visibleParentFile"
      :title="$t('all.enterInformationBelow')"
      width="50%"
      :key="componentKey"
      centered
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancelModalParent">
          {{ $t("action.annuler") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loadingData"
          :disabled="loadingData"
          @click="updateParent"
        >
          {{ $t("action.confirmer") }}
        </a-button>
      </template>
      <div class="container">
        <span class="span row">
          <label class="col-md-6">
            {{ $t("liste.nomPere") }}
            <a-input
              id="nomPere"
              type="text"
              name="nomPere"
              :default-value="activeParent.father.firstName"
            />
          </label>
          <label class="col-md-6">
            {{ $t("liste.prenomPere") }}
            <a-input
              id="prenomPere"
              type="text"
              name="prenomPere"
              :default-value="activeParent.father.lastName"
            />
          </label>
        </span>
        <span class="span row">
          <label class="col-md-6">
            {{ $t("liste.nomMere") }}
            <a-input
              id="nomMere"
              class="inp"
              type="text"
              name="nomMere"
              :default-value="activeParent.mother.firstName"
            />
          </label>
          <label class="col-md-6">
            {{ $t("liste.prenomMere") }}
            <a-input
              id="prenomMere"
              class="inp"
              type="text"
              name="prenomMere"
              :default-value="activeParent.mother.lastName"
            />
          </label>
        </span>
        <span class="span row">
          <label class="col-md-6">
            {{ $t("liste.telPere") }}
            <a-input
              id="telephonePere"
              type="text"
              name="telephonePere"
              :default-value="activeParent.father.phone"
            />
          </label>
          <label class="col-md-6">
            {{ $t("liste.telMere") }}
            <a-input
              id="telephoneMere"
              class="inp"
              type="text"
              name="telephoneMere"
              :default-value="activeParent.mother.phone"
            />
          </label>
        </span>
        <span class="span row">
          <label class="col-md-6">
            {{ $t("liste.metierPere") }}
            <a-input
              id="FonctionPere"
              type="text"
              name="FonctionPere"
              :default-value="activeParent.father.job"
            />
          </label>
          <label class="col-md-6">
            {{ $t("liste.metierMere") }}
            <a-input
              id="FonctionMere"
              class="inp"
              type="text"
              name="FonctionMere"
              :default-value="activeParent.mother.job"
            />
          </label>
        </span>
        <span class="span row">
          <label class="col-md-6">
            {{ $t("liste.emailParents") }}
            <a-input
              id="emailParent"
              type="text"
              name="emailParent"
              :default-value="activeParent.email"
            />
          </label>
          <label class="col-md-6">
            {{ $t("liste.adresseParents") }}
            <a-input
              id="adressParent"
              class="inp"
              type="text"
              name="adressParent"
              :default-value="activeParent.adress"
            />
          </label>
        </span>
        <div class="row justify-content-center border-with-text">
          <span class="border-text">{{ $t("menu.liste") }}</span>
          <a-list
            :grid="{
              gutter: activeStudentlist.length > 4 ? 12 : 24,
              column:
                activeStudentlist.length > 4 ? 4 : activeStudentlist.length,
            }"
            item-layout="v"
            :data-source="activeStudentlist"
            :bordered="false"
          >
            <a-list-item slot="renderItem" slot-scope="item, index">
              <a-list-item-meta
                @click="() => openProfile(item.id)"
                :description="item.description"
              >
                <a slot="title" :class="index">{{ item.title }}</a>
                <a-avatar
                  size="large"
                  slot="avatar"
                  v-if="item.photo"
                  :src="settings.base_url + '/' + item.photo"
                />
                <a-avatar
                  size="large"
                  slot="avatar"
                  style="color: #f56a00; background-color: #fde3cf"
                  v-else
                >
                  {{ item.title[0].toUpperCase() }}
                </a-avatar>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="visibleAttestationPresence"
      :title="$t('all.enterInformationBelow')"
      :width="500"
      :key="componentKey"
    >
      <template slot="footer">
        <a-button
          key="back"
          @click="() => (this.visibleAttestationPresence = false)"
        >
          {{ $t("action.annuler") }}
        </a-button>
        <a-button
          type="primary"
          :loading="loadingData"
          :disabled="loadingData"
          @click="attestationPresencePDF(activeStudent)"
        >
          <img
            style="border-radius: 30%; margin-right: 5px"
            width="20"
            src="resources/images/flags/tunisia.svg"
            alt="Arabic"
          />
          {{ $t("action.imprimer") }}
        </a-button>
        <a-button
          type="primary"
          :loading="loadingData"
          :disabled="loadingData"
          @click="attestationPresenceFr(activeStudent)"
        >
          <img
            style="border-radius: 30%; margin-right: 5px"
            width="20"
            src="resources/images/flags/france.svg"
            alt="Arabic"
          />
          {{ $t("action.imprimer") }}
        </a-button>
      </template>
      <div>
        <span class="span">
          <label>
            {{ $t("liste.regionalEducation") }}
            <a-input
              id="cr"
              type="text"
              name="cr"
              :default-value="schoolDetails.delegeRegional"
            />
          </label>
        </span>
        <span class="span">
          <label>
            {{ $t("etablissement.directrice") }}
            <a-input
              id="dir"
              type="text"
              name="dir"
              :default-value="schoolDetails.headMaster"
            />
          </label>
        </span>
        <span class="span">
          <label>
            {{ $t("bulletin.nom") }}
            <a-input
              id="nomStudent"
              type="text"
              name="nomStudent"
              :default-value="
                activeStudent.firstName + ' ' + activeStudent.lastName
              "
            />
          </label>
        </span>
        <span class="span">
          <label>
            {{ $t("profil.dateNaissance") }}
            <a-form-item>
              <a-date-picker
                :placeholder="$t('profil.dateNaissance')"
                format="DD-MM-YYYY"
                v-model="attestationPresenceBirthdate"
                v-decorator="[
                  'BirthDate',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.dateNaissance'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </label>
        </span>
        <span class="span">
          <label>
            {{ $t("profil.classe") }}
            <a-input
              id="className"
              type="text"
              name="className"
              :default-value="activeStudent.scolarite"
            />
          </label>
        </span>

        <span class="span">
          <label>
            {{ $t("punition.raison") }}

            <a-input
              :placeholder="$t('punition.raison')"
              id="reason"
              name="reason"
              type="textarea"
              default-value="........................................"
              v-decorator="[
                'reason',
                {
                  trigger: ``,
                },
              ]"
            />
          </label>
        </span>

        <span class="span">
          <label>
            {{ $t("liste.recuEn") }}
            <a-input
              id="recuEn"
              type="text"
              name="recuEn"
              default-value="...................."
            />
          </label>
        </span>
        <span
          class="span"
          v-if="
            schoolDetails && schoolDetails.stamp && schoolDetails.stamp !== ''
          "
        >
          <label>
            {{ $t("bulletin.showStamp") }}
            <a-switch v-model="showStamp" class="ml-2" />
          </label>
        </span>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import router from "@/router";
import $ from "jquery";
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import { mapState } from "vuex";
import "jspdf-autotable";
import { notification } from "ant-design-vue";
import _ from "lodash";
import MultiPaymentValidater from "@/views/eleves/profile/components/multiPaymentValidater";
import { getRangeMonthsSY } from "@/utilities/settings";

import moment, { duration } from "moment";
import axios from "axios";

const { AwesomeQR } = require("awesome-qr");

function readbleDate(dateString) {
  const date = new Date(dateString);
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = date.getFullYear();
  return mm + "/" + dd + "/" + yyyy;
}
function parentFormater(parent) {
  return {
    name:
      parent.father.firstName +
      " " +
      parent.father.lastName +
      " et " +
      parent.mother.firstName +
      " " +
      parent.mother.lastName,
    children: parent.students ? parent.students.length : 0,
    motherPhone: parent.mother.phone,
    fatherPhone: parent.father.phone,
    id: parent._id,
  };
}

export default {
  components: {
    MultiPaymentValidater,
  },
  created() {
    this.$watch(
      () => this.settings.schoolarYears,
      (newValue, oldValue) => {
        this.init();
      }
    );

    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );
    if (building) this.reductionType = building.reductionType || "pourcentage";

    try {
      this.loadingLogins = true;
      this.init();
      this.loadingLogins = false;
    } catch (error) {
      console.error(
        "Failed on load ,error handling strategy...",
        error.message
      );
    }
  },
  data() {
    return {
      reductionType: "dinar",
      selectedClassRoom: undefined,
      suggestionTimeout: null,
      loadingLogins: true,
      suggestionParentList: [],
      selectedSuggestionParent: undefined,
      visibleParentSuggestion: false,
      visibleDeleteReasson: false,
      deleteReason: "",
      schoolDetails: {},
      loadingBadges: false,
      componentKey: 0,
      disabledSP: false,
      activeBuilding: {},
      filtredTable: [],
      tableLeading: true,
      newStudentForm: this.$form.createForm(this),
      newStudent: {
        birthDate: null,
        gender: null,
        classRoom: null,
        parent: null,
      },
      classRoomList: [],
      newParent: false,
      addNewParent: false,
      parentsList: [],
      parentsCount: 0,
      activeParentChange: {},
      activeStudent: {},
      attestationPresenceBirthdate: "",
      activeParent: {
        father: {
          firstname: "",
          lastName: "",
          job: "",
          email: "",
        },
        mother: {
          firstname: "",
          lastName: "",
          job: "",
          email: "",
        },
        students: [
          {
            firstName: "",
            lastName: "",
            _id: "",
          },
        ],
      },
      activeStudentlist: [],
      editingKey: "",
      visibleParentFile: false,
      visibleAttestationPresence: false,
      data: [],
      cacheData: null,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      currentYear: "",
      value: 1,
      value1: 1,
      loadingData: false,
      visibleData: false,
      visibleFichePersonnel: false,
      fichePersonnelData: {
        // Parent Info
        father: {
          firstName: "",
          lastName: "",
          phone: "",
          job: "",
        },
        mother: {
          firstName: "",
          lastName: "",
          phone: "",
          job: "",
        },
        email: "",
        adress: "",
        // Personal Info
        numberOfRegistrations: null,
        financialStatus: "",
        numberOfChildren: 0,
        entryDate: null,
        previousInstitution: "",
        exitDate: null,
        exitReason: "",
        secondarySchool: "",
        accompanyingDocuments: "",
        notes: "",
        effort: "",
        behavior: "",
        attendance: "",
        includePhoto: false,
        studentPhoto: "",
      },
      columns: [
        {
          title: this.$t("liste.nomPrenom"),
          dataIndex: "firstName",
          key: "firstName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "firstName",
          },
          onFilter: (value, record) =>
            record.firstName.toLowerCase().includes(value.toLowerCase()) ||
            record.lastName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("liste.scolarite"),
          dataIndex: "scolarite",
          key: "scolarite",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "scolarite",
          },
          onFilter: (value, record) =>
            record.scolarite.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("liste.attestation"),
          dataIndex: "attestationPresence",
          key: "attestationPresence",
          scopedSlots: {
            customRender: "attestationPresence",
          },
        },
        {
          title: this.$t("liste.inscription"),
          dataIndex: "inscription",
          key: "inscription",
          scopedSlots: {
            customRender: "inscription",
          },
        },
        {
          title: this.$t("liste.supprimerAnnuler"),
          dataIndex: "delete",
          scopedSlots: { customRender: "delete" },
          fixed: "right",
        },
        {
          title: "Paiement",
          dataIndex: "payment",
          scopedSlots: { customRender: "payment" },
          fixed: "right",
        },
      ],
      modePaiement: "M",
      showStamp: true,
    };
  },
  computed: {
    ...mapState(["settings", "user"]),
    headerInscrit: function () {
      return this.filtredTable.reduce(
        (a, v) => (v.scolarite === this.$t("liste.nonInscrit") ? a + 1 : a),
        0
      );
    },
    headerNonInscrit: function () {
      return (
        this.filtredTable.length -
        this.filtredTable.reduce(
          (a, v) => (v.scolarite === this.$t("liste.nonInscrit") ? a + 1 : a),
          0
        )
      );
    },
    countGarcon: function () {
      return (
        parseFloat(
          (
            (this.filtredTable.reduce(
              (a, v) => (v.gender === "male" ? a + 1 : a),
              0
            ) *
              100) /
            this.filtredTable.length
          ).toFixed(1)
        ) || 0
      );
    },
    countFille: function () {
      return (
        parseFloat(
          (
            (this.filtredTable.reduce(
              (a, v) => (v.gender === "female" ? a + 1 : a),
              0
            ) *
              100) /
            this.filtredTable.length
          ).toFixed(1)
        ) || 0
      );
    },
    schoolAccessTemplateID() {
      //specefic template
      const accessTemplates = [{ id: "AC", dbName: "arcEnCiel" }];
      let templateId = accessTemplates.find(
        (e) => e.dbName == this.schoolDetails.dbName
      );
      templateId = templateId ? templateId.id : "";
      return templateId;
    },
  },
  methods: {
    changeModeP(v) {
      this.modePaiement = v;
    },
    async init() {
      this.currentYear = this.settings.schoolarYears.find(
        (sc) => sc._id == this.settings.activeSchoolarYear
      ).name;
      this.activeBuilding = this.settings.image.find(
        (building) => building.dbName == this.settings.activeBuilding
      );
      this.schoolDetails = this.user.building.find(
        (el) => el.dbName == this.settings.activeBuilding
      );
      this.tableLeading = true;
      apiClient
        .post("/classrooms/filter", {
          query: {
            status: "active",
          },
          aggregation: {
            $project: {
              _id: 1,
              name: 1,
              inscription: 1,
              monthly: 1,
              tranches: 1,
            },
          },
        })
        .then((res) => {
          this.classRoomList = res.data.map((item) => ({
            name: item.name,
            id: item._id,
            inscription: item.inscription,
            monthly: item.monthly,
            tranches: item.tranches,
          }));
        });
      apiClient
        .post("/parents/filter", {
          query: { status: "active" },
          aggregation: {
            $project: {
              father: { firstName: 1, lastName: 1, phone: 1 },
              mother: { firstName: 1, lastName: 1, phone: 1 },
              students: 1,
              status: 1,
              financialStatus: 1,
              numberOfChildren: 1,
              adress: 1,
            },
          },
        })
        .then((res) => {
          this.parentsList = res.data.map((item) => parentFormater(item));
          this.parentsCount = res.data.filter(function (item) {
            return item.status === "active" || !item.status;
          }).length;
        });
      apiClient
        .post("/students/filter", {
          query: {
            status: "active",
          },
          aggregation: [
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "parents",
                localField: "parent",
                foreignField: "_id",
                as: "parent",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classRoom",
              },
            },
            {
              $project: {
                _id: 1,
                parent: {
                  father: { $arrayElemAt: ["$parent.father", 0] },
                  mother: { $arrayElemAt: ["$parent.mother", 0] },
                  _id: { $arrayElemAt: ["$parent._id", 0] },
                  adress: 1,
                  email: 1,
                  financialStatus: 1,
                  numberOfChildren: 1,
                  students: 1,
                },
                classRoom: { $arrayElemAt: ["$classRoom.name", 0] },
                BirthDate: 1,
                birthPlace: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                photo: 1,
                adress: 1,
                uniqueId: 1,
                schoolarYearsHistory: 1,
                notes: 1,
                personalInfo: 1,
              },
            },
          ],
        })
        .then((res) => {
          this.data = res.data.map((item) => this.studentFormater(item));

          this.data.sort((a, b) => {
            let fullname_a = a.firstName + a.lastName;
            let fullname_b = b.firstName + b.lastName;
            return fullname_a.localeCompare(fullname_b);
          });

          this.cacheData = this.data;
          this.tableLeading = false;
          this.filtredTable = this.data;
        });
    },
    verifyAccess: function (accessRoles) {
      const existing = this.user.access;
      if (existing.all) return true;
      if (
        existing.liste_eleves === true ||
        (existing.liste_eleves &&
          existing.liste_eleves.length > 0 &&
          existing.liste_eleves.find((e) => accessRoles.includes(e)))
      )
        return true;
      else return false;
    },
    moment,
    debounceField: _.debounce(function (e) {
      let field = e.target.id;
      this.newStudentForm.setFieldsValue({
        [field]: e.target.value,
      });
      this.newStudentForm.validateFields([`${field}`]);
    }, 50),

    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    openImport() {
      router.push({ path: "/uploadeleves" }).catch((error) => {
        console.info(error);
      });
    },
    openArchive() {
      router.push({ path: "/archive_elevesliste" }).catch((error) => {
        console.info(error);
      });
    },
    openProfile(id) {
      router.push(`/eleve/${id}`).catch((error) => {
        console.info(error);
      });
    },
    studentFormater(student) {
      var modified = student;
      modified.BirthDate = readbleDate(modified.BirthDate);
      modified.createdAt = readbleDate(modified.createdAt);
      modified.id = student._id;
      return {
        ...modified,
        scolarite: student.classRoom
          ? student.classRoom
          : this.$t("liste.nonInscrit"),
      };
    },
    updateParent() {
      const id = this.activeParent._id;
      this.loadingData = true;
      const update = {
        father: {
          firstName: $("#nomPere").val(),
          lastName: $("#prenomPere").val(),
          job: $("#FonctionPere").val(),
          phone: $("#telephonePere").val(),
        },
        mother: {
          firstName: $("#nomMere").val(),
          lastName: $("#prenomMere").val(),
          job: $("#FonctionMere").val(),
          phone: $("#telephoneMere").val(),
        },
        email: $("#emailParent").val(),
        adress: $("#adressParent").val(),
      };
      apiClient
        .patch(`/parents/${id}`, { data: update })
        .then((res) => {
          this.data = this.data.map((student) =>
            student.parent[0]._id == id
              ? {
                  ...student,
                  parent: [
                    {
                      ...student.parent[0],
                      ...update,
                    },
                  ],
                }
              : student
          );
          this.parentsList = this.parentsList.map((parent) =>
            parent.id == id ? parentFormater({ ...update, _id: id }) : parent
          );
          this.$message.success(this.$t("success.parentMSA"));

          this.visibleParentFile = false;
          this.loadingData = false;
          //location.reload();
        })
        .catch(() => {
          this.visibleParentFile = false;
          this.loadingData = false;
          this.openNotificationWithIcon(
            "warning",
            this.$t("changementNonEffectue"),
            this.$t("champsVides")
          );
        });
    },
    triggerAddNewParent() {
      this.disabledSP = !this.disabledSP;
      // this.newParent = !this.newParent;
    },
    handleNewStudentParentOnChange(value) {
      this.newStudent.parent = value;
    },
    handleNewStudentDateOnChange(value) {
      this.newStudent.birthDate = value;
    },
    handleNewStudentClassRoomOnChange(value) {
      this.newStudent.classRoom = value;
    },
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    parentFile(id) {
      this.componentKey += 1;
      this.activeParent = JSON.parse(JSON.stringify(id.parent[0]));
      //  this.activeStudentlist = this.activeParent.students.map((el) => {
      //    var description;
      //    var student = this.data.find((item) => item._id == el);
      //    /*  if (student.status == "active")
      //      description = student.classRoom
      //        ? `Inscrit au "${student.classRoom.name}"`
      //        : "Ne pas encore inscrit";
      //    else description = "Cet élève a été supprimé"; */
      //    return {
      //      id: student._id,
      //      title: student.firstName + " " + student.lastName,

      //      photo: student.photo,
      //    };
      //  });
      this.activeStudentlist = this.activeParent.students.reduce(
        (toReturn, studentID) => {
          var student = this.data.find((item) => item._id == studentID);
          if (student)
            toReturn.push({
              id: student._id,
              title: student.firstName + " " + student.lastName,
              photo: student.photo,
            });
          return toReturn;
        },
        []
      );

      this.visibleParentFile = true;
    },
    attestationPresence(id) {
      if (id) {
        this.componentKey += 1;
        this.activeParent = JSON.parse(JSON.stringify(id.parent[0]));
        this.activeStudent = id;
        this.attestationPresenceBirthdate = id.BirthDate;
        this.activeStudentlist = this.activeParent.students.map((el) => {
          var student = this.data.find((item) => item._id == el);
          return {
            id: student._id,
            title: student.firstName + " " + student.lastName,

            photo: student.photo,
          };
        });
        this.showStamp = true;
        this.visibleAttestationPresence = true;
      }
    },
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
        this.cacheData = this.data;
      }
    },
    save(id) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => id === item.id)[0];
      const targetCache = newCacheData.filter((item) => id === item.id)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
        const update = {
          firstName: target.firstName,
          lastName: target.lastName,
          phone: target.phone,
          adress: target.adress,
        };
        apiClient
          .patch(`/students/${id}`, { data: update })
          .then()
          .catch(() => {
            this.openNotificationWithIcon(
              "warning",
              this.$t("changementNonEffectue")
            );
          });
      }
      this.editingKey = "";
    },
    cancel(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => id === item.id)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },

    deleteStudent(id) {
      this.selectedStudentId = id;
      this.visibleDeleteReasson = true;
    },

    onDelete() {
      const id = this.selectedStudentId;
      this.visibleDeleteReasson = false;
      apiClient
        .delete(`/students/${id}`)
        .then((res) => {
          apiClient
            .patch(`/students/${id}`, {
              data: {
                reasonArchive: this.deleteReason,
              },
            })
            .then((res) => {
              const dataSource = [...this.data];
              this.data = dataSource.filter((item) => item.id !== id);
              this.cacheData = this.data;
              this.openNotificationWithIcon(
                "success",
                this.$t("success.eleveSupp")
              );
            })
            .catch((e) => this.$message.warning(this.$t("error.supp")));
        })
        .catch((e) => this.$message.warning(this.$t("error.supp")))
        .finally(() => {
          this.deleteReason = "";
        });
    },
    onCancelInscription(id) {
      apiClient
        .post(`/payment/scolarite/cancel/${id}`)
        .then((res) => {
          const dataSource = [...this.data];
          let objIndex = this.data.findIndex((obj) => obj.id == id);
          this.data[objIndex].scolarite = "Non inscrit";
          this.cacheData = this.data;
          this.$emit("reload");
          this.openNotificationWithIcon(
            "success",
            this.$t("success.InscriptionAnnulee")
          );
        })
        .catch((e) => {
          this.$message.warning(e.response.data.error.message);
        });
    },
    async addStudent(e) {
      this.newStudentForm.setFields({
        [parent]: { errors: [] },
      });
      this.newStudentForm.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          // this.loadingData = true;
          let student = values;
          student.schoolarYearsHistory = {};
          student.schoolarYearsHistory[this.settings.activeSchoolarYear] =
            values.classRoom ? values.classRoom : null;

          if (this.newParent) {
            const data = values.parentdata;
            await apiClient.put("/parents", { data }).then((res) => {
              const { parent } = res.data;
              delete student.parentdata;
              student.parent = parent._id;
              this.parentsList.push(parentFormater(parent));
            });
            this.newParent = false;
          }
          await apiClient
            .put("/students", student)
            .then((res) => {
              student._id = res.data._id;
              this.data.push(this.studentFormater(res.data));
              this.$message.success(this.$t("success.eleveAjout"));
            })
            .catch((e) => {
              console.error(e);
              this.$message.warning(this.$t("error.ajout"));
            })
            .finally(() => {
              this.newStudentForm.resetFields();
              this.visibleData = false;
              this.loadingData = false;
            });

          if (values.classRoom) {
            const activeSchoolarYear = this.settings.activeSchoolarYear;
            const schoolarYears = this.settings.schoolarYears;
            let newInscription = {};
            schoolarYears.forEach((elem) => {
              if (elem._id == activeSchoolarYear) {
                newInscription.end = elem.end;
                newInscription.start = elem.start;
              }
            });
            newInscription.student = student._id;
            newInscription.typeAS = "S";
            newInscription.classroom = values.classRoom;
            newInscription.mode = this.modePaiement;

            let repReduction = {};

            const range = getRangeMonthsSY(activeSchoolarYear, schoolarYears);
            if (newInscription.mode == "M")
              for (const key of range) repReduction[key] = 0;
            else if (this.selectedClassRoom.tranches)
              for (const e of this.selectedClassRoom.tranches)
                repReduction[e.trancheNumber] = 0;

            if (this.selectedClassRoom.inscription)
              repReduction["inscription"] = 0;

            newInscription.repReduction = repReduction;

            apiClient
              .put(`/payment`, newInscription)
              .then((res) => {
                let className = this.classRoomList.find(
                  (e) => e.id == values.classRoom
                );
                this.data = this.data.map((e) => {
                  if (e.id === student._id) {
                    e.scolarite =
                      className && className.name ? className.name : "--";
                  }
                  return e;
                });
              })
              .catch((e) => {
                console.error(e);
              });
          }
        }
      });
    },
    handleCancelModalAdd(e) {
      this.visibleData = false;
      this.addNewParent = false;
      this.selectedClassRoom = undefined;
    },
    handleCancelModalParent(e) {
      this.visibleParentFile = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // Function to add a new page with sections
    addPageWithSections(pdf, index) {
      if (index > 0 && index % 4 === 0) {
        pdf.addPage();
      }
      // Define the page size (A4)
      const pageWidth = 210;
      const pageHeight = 297;

      // Define section dimensions
      const sectionWidth = pageWidth / 2;
      const sectionHeight = pageHeight / 2;
      // Draw section borders on the new page
      pdf.rect(0, 0, sectionWidth, sectionHeight);
      pdf.rect(sectionWidth, 0, sectionWidth, sectionHeight);
      pdf.rect(0, sectionHeight, sectionWidth, sectionHeight);
      pdf.rect(sectionWidth, sectionHeight, sectionWidth, sectionHeight);
    },
    // Function to add image to the top right corner of a section
    addImageToSection(pdf, x, y, width, height, imgData) {
      pdf.addImage(imgData, "JPEG", x, y, width, height);
    },

    async drawSection(pdf, parent, index, logo, imgData, schoolName) {
      pdf.setPage(Math.floor(index / 4) + 1);
      // Define the page size (A4)
      const pageWidth = 210;
      const pageHeight = 297;

      // Define section dimensions
      const sectionWidth = pageWidth / 2;
      const sectionHeight = pageHeight / 2;
      // Calculate section coordinates
      const x = (index % 2) * sectionWidth;
      const y = (Math.floor(index / 2) % 2) * sectionHeight;
      //add building logo
      const imageWidth = 15;
      const imageHeight = 15;
      //add qrcode image

      const qrData = btoa(
        parent._id +
          "." +
          this.settings.activeBuilding +
          "." +
          this.settings.base_url
      );

      let students = parent.students
        .map((s) => {
          if (s.classRoom && s.classRoom.name)
            return s.fullName + " (" + s.classRoom.name + " )";
          return s.fullName;
        })
        .join("\n");
      pdf.setFont("Amiri", "bold");

      pdf.setFontSize(15);

      pdf.text(`Informations de connexion`, x + 15, y + 15);
      pdf.setFont("Amiri", "normal");
      pdf.setFontSize(12);

      pdf.text(schoolName, x + 50, y + 20, "center");

      pdf.setFontSize(10);

      // Add content to the section
      pdf.text(
        `Père : ${this.capitalizeFirstLetter(parent.father)}`,
        x + 10,
        y + 32
      );
      pdf.text(
        `Mére: ${this.capitalizeFirstLetter(parent.mother)}`,
        x + 10,
        y + 40
      );
      pdf.text(`Les élèves associés : `, x + 10, y + 48);

      pdf.text(`${students}`, x + 40, y + 48);
      const textChildrenDims = pdf.getTextDimensions(students);

      pdf.text(`Lien de connexion :`, x + 10, y + 56 + textChildrenDims.h);
      pdf.text(
        `https://parent.edtrust.tn/ \nou application Mobile EdParent`,
        x + 39,
        y + 56 + textChildrenDims.h
      );
      pdf.text(
        `Nom d'utilisateur : ${parent.userName}`,
        x + 10,
        y + 64 + textChildrenDims.h + 3
      );
      pdf.text(
        `Mot de passe : ${atob(parent.pp)}`,
        x + 10,
        y + 72 + textChildrenDims.h + 3
      );

      // Add QR code

      await new AwesomeQR({
        text: qrData,
        logoImage: logo,
        size: 200,
        correctLevel: 1,
        logoScale: 0.2,
        logoMargin: 0,
        logoCornerRadius: 8,
        whiteMargin: false,
        dotScale: 1,
        autoColor: true,
        binarize: false,
        binarizeThreshold: 128,
        backgroundColor: "#FFFFFF",
        margin: 3,
      })
        .draw()
        .then((dataURL) => {
          pdf.setPage(Math.floor(index / 4) + 1);

          pdf.addImage(dataURL, "JPEG", x + 33, y + 90, 40, 40);
          this.addImageToSection(
            pdf,
            x + sectionWidth - imageWidth - 5,
            y + 5,
            imageWidth,
            imageHeight,
            imgData
          );
        });
    },
    async generateLoginsParents() {
      await apiClient.get("/parents/parentslogins").then(async (res) => {
        const parentsLogins = res.data;
        this.loadingBadges = true;
        const pdf = new JsPDF();
        pdf.setFont("Amiri");
        pdf.setFontSize(10);
        let arrayOfImages = this.settings.image;
        for (var i = 0; i < arrayOfImages.length; i++) {
          if (this.settings.activeBuilding === arrayOfImages[i].db)
            var imgSrc = arrayOfImages[i].logo;
        }

        const schoolDetails = this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0];

        const schoolName = schoolDetails.ArabicName || schoolDetails.name;

        let imgData = "data:image/jpeg;base64, ";

        await axios
          .get(imgSrc, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            imgData += Buffer.from(response.data, "binary").toString("base64");
          });

        let logo = "data:image/jpeg;base64, ";

        await axios
          .get("favicon.png", {
            responseType: "arraybuffer",
          })
          .then((response) => {
            logo += Buffer.from(response.data, "binary").toString("base64");
          });

        let promises = [];
        // Iterate through parent data
        parentsLogins.forEach((parent, index) => {
          // Check if a new page is needed
          this.addPageWithSections(pdf, index);
          promises.push(
            this.drawSection(pdf, parent, index, logo, imgData, schoolName)
          );
        });
        // Save or display the PDF
        await Promise.all(promises)
          .then((values) => {
            //page numbering
            /* const pages = pdf.internal.getNumberOfPages();
            const pageWidth = pdf.internal.pageSize.width;
            const pageHeight = pdf.internal.pageSize.height;
            pdf.setFontSize(8); //Optional

            for (let j = 1; j < pages + 1; j++) {
              let horizontalPos = pageWidth / 2;
              let verticalPos = pageHeight - 5;
              pdf.setPage(j);
              pdf.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
                align: "center",
              });
            }*/

            this.loadingBadges = false;
            pdf.save("Logins parents.pdf");
          })
          .catch((reason) => {
            console.log(reason);
          });
      });
    },

    async drawBadge(doc, x, y, imgData, logo, student, pageNumber, schoolName) {
      doc.setPage(pageNumber);
      var today = new Date();
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      doc.setFont("Amiri");
      doc.setFontSize(10);
      let birthDay = new Date(student.BirthDate);
      doc.setFont("Amiri", "Bold");
      doc.text(47 + x, 21 + y, "Nom et prénom: ");
      doc.setFont("Amiri", "normal");
      doc.setFontSize(9);
      doc.text(
        47 + x,
        27 + y,
        this.capitalizeFirstLetter(student.firstName + " " + student.lastName)
      );
      doc.setFontSize(10);
      doc.setFont("Amiri", "Bold");
      doc.text(47 + x, 33 + y, "Date de naissance:");
      doc.setFont("Amiri", "normal");
      doc.text(47 + x, 39 + y, birthDay.toLocaleDateString("fr-FR", options));
      doc.setFontSize(5);
      doc.text(
        52 + x,
        47 + y,
        "Géneré le : " + " " + today.toLocaleDateString("fr-FR", options)
      );

      let qrDataDetail =
        student._id +
        "." +
        this.settings.activeBuilding +
        "." +
        this.settings.activeSchoolarYear +
        "." +
        this.settings.base_url;

      const qrData = btoa(qrDataDetail);

      await new AwesomeQR({
        text: qrData,
        logoImage: logo,
        size: 200,
        correctLevel: 1,
        logoScale: 0.2,
        logoMargin: 0,
        logoCornerRadius: 8,
        whiteMargin: false,
        dotScale: 1,
        autoColor: true,
        binarize: false,
        binarizeThreshold: 128,
        bindElement: true,
        backgroundColor: "#FFFFFF",
        margin: 3,
      })
        .draw()
        .then((dataURL) => {
          doc.setPage(pageNumber);
          doc.addImage(dataURL, "JPEG", 7 + x, 15 + y, 30, 30);
          doc.addImage(imgData, "JPEG", 75 + x, 6 + y, 10, 10);
          doc.setFontSize(13);
          doc.text(schoolName, 46 + x, 11 + y, "center");

          doc.setFillColor(0, 0, 0);
          doc.rect(4 + x, 4 + y, 84, 48, "S");
        });
    },
    async drawNewBadge(
      doc,
      x,
      y,
      imgData,
      logo,
      student,
      pageNumber,
      schoolName,
      defaultStudentPhoto,
      classroom
    ) {
      doc.setPage(pageNumber);

      doc.setLineWidth(1);

      const backgroundColor = "#81b6f7";
      const lineColor = "#61a6fa";
      const photoColor = "#ffffff";

      const page_width = doc.internal.pageSize.getWidth() / 2;
      const page_height = doc.internal.pageSize.getHeight() / 2;
      const ctx = doc.context2d;

      doc.setFillColor(backgroundColor);
      doc.setDrawColor(lineColor);

      doc.rect(x + 0, y + 50, page_width, page_height - 50, "FD");

      doc.setFillColor(photoColor);
      doc.setDrawColor(lineColor);
      doc.setLineWidth(0.6);
      doc.circle(x + page_width / 2, y + 50, 20, "FD");

      // draw student photo
      // -------------------------------------------------------------
      const studentPhoto = new Image();
      if (student.photo)
        studentPhoto.src = this.settings.base_url + "/" + student.photo;

      let imageSize = 39; // Adjust this to the desired image size
      let centerX = x + page_width / 2; // Adjust this to the desired X coordinate
      let centerY = y + 50; // Adjust this to the desired Y coordinate

      // Create a circular clipping path using context2d
      ctx.save();
      ctx.beginPath();
      ctx.arc(centerX, centerY, imageSize / 2, 0, Math.PI * 2);
      ctx.clip();

      // Draw the original image within the circular clipping path
      ctx.drawImage(
        student.photo ? studentPhoto : defaultStudentPhoto,
        centerX - imageSize / 2,
        centerY - imageSize / 2,
        imageSize,
        imageSize
      );

      // Reset the clipping path
      ctx.restore();
      ctx.closePath();
      // -------------------------------------------------------------

      doc.setFont("Amiri", "Bold");
      doc.setFontSize(22);
      doc.setTextColor("#ffffff");

      doc.text(
        x + page_width / 2,
        y + 78,
        this.capitalizeFirstLetter(student.firstName + " " + student.lastName),
        { align: "center" }
      );
      doc.setFont("Amiri", "normal");
      doc.setFontSize(15);
      doc.text(x + page_width / 2, y + 88, classroom, {
        align: "center",
      });

      let qrDataDetail =
        student._id +
        "." +
        this.settings.activeBuilding +
        "." +
        this.settings.activeSchoolarYear +
        "." +
        this.settings.base_url;

      const qrData = btoa(qrDataDetail);

      await new AwesomeQR({
        text: qrData,
        logoImage: logo,
        size: 200,
        correctLevel: 1,
        logoScale: 0.2,
        logoMargin: 0,
        logoCornerRadius: 8,
        whiteMargin: false,
        dotScale: 1,
        autoColor: true,
        binarize: false,
        binarizeThreshold: 128,
        bindElement: true,
        backgroundColor: "#FFFFFF",
        margin: 3,
      })
        .draw()
        .then((dataURL) => {
          doc.setPage(pageNumber);
          doc.addImage(
            dataURL,
            "PNG",
            x + (page_width / 2 - 17.5),
            y + page_height / 1.5,
            35,
            35
          );

          // logo and school name
          // -------------------------------------------------------------
          imageSize = 15;
          const text = schoolName;
          centerX = page_width / 2;
          centerY = y + 15;
          const padding = 5;
          doc.setFontSize(13);

          const totalWidth = imageSize + padding + doc.getTextWidth(text);
          const startX = x + centerX - totalWidth / 2;

          doc.addImage(
            imgData,
            "JPEG",
            startX,
            centerY - imageSize / 2,
            imageSize,
            imageSize
          );

          doc.setTextColor("#000000");

          doc.text(startX + imageSize + padding, centerY, text);
          doc.setFillColor(0, 0, 0);
          doc.setLineWidth(0.5);
          doc.rect(x, y, page_width, page_height, "S");
          // -------------------------------------------------------------
        });
    },
    async generateBadges() {
      const start = performance.now();
      this.loadingBadges = true;
      const doc = new JsPDF();
      const pdf_height = doc.internal.pageSize.height;
      const pdf_width = doc.internal.pageSize.width;

      doc.setFont("Amiri");

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db)
          var imgSrc = arrayOfImages[i].logo;
      }

      const schoolDetails = this.user.building.filter(
        (el) => el.dbName == this.settings.activeBuilding
      )[0];

      const schoolName = schoolDetails.ArabicName || schoolDetails.name;

      let imgData = "data:image/jpeg;base64, ";

      await axios
        .get(imgSrc, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          imgData += Buffer.from(response.data, "binary").toString("base64");
        });

      const data = {};
      let logo = "data:image/jpeg;base64, ";

      await axios
        .get("favicon.png", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          logo += Buffer.from(response.data, "binary").toString("base64");
        });

      this.filtredTable.forEach((student) => {
        if (!data[student.classRoom]) data[student.classRoom] = [];
        data[student.classRoom].push(student);
      });

      let y = 0;
      let left = true;
      let firstPage = true;
      let currentPage = 1;

      // array for optimization
      const promises = [];

      for (const classroom of Object.keys(data)) {
        doc.setFontSize(17);
        y = 15;
        if (!firstPage) {
          doc.addPage();
          currentPage++;
          left = true;
        }
        const width = doc.getTextWidth(classroom);
        doc.text(classroom, pdf_width / 2 - width / 2, 10);
        const classroomData = data[classroom];
        for (let i = 0; i < classroomData.length; i++) {
          if (left)
            promises.push(
              this.drawBadge(
                doc,
                16,
                y,
                imgData,
                logo,
                classroomData[i],
                currentPage,
                schoolName
              )
            );
          else
            promises.push(
              this.drawBadge(
                doc,
                100,
                y,
                imgData,
                logo,
                classroomData[i],
                currentPage,
                schoolName
              )
            );

          if (!left) {
            y += 48.1;
            if (y + 68.1 >= pdf_height) {
              y = 0;
              left = false;
              doc.addPage();
              currentPage++;
            }
          }

          left = !left;
        }

        firstPage = false;
      }

      await Promise.all(promises)
        .then((values) => {
          //page numbering
          const pages = doc.internal.getNumberOfPages();
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;
          doc.setFontSize(8); //Optional

          for (let j = 1; j < pages + 1; j++) {
            let horizontalPos = pageWidth / 2;
            let verticalPos = pageHeight - 5;
            doc.setPage(j);
            doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
              align: "center",
            });
          }

          this.loadingBadges = false;
          doc.save("Liste des badges.pdf");
        })
        .catch((reason) => {
          console.log(reason);
        });
      const end = performance.now();
      console.info(end - start, "gtag");
      this.$gtag.event("Imprimer les badges ", {
        event_category: "PRINT",
        print_what: "Liste des élèves section",
        duration: end - start,
      });
    },
    async generateNewBadges() {
      const start = performance.now();
      this.loadingBadges = true;
      const doc = new JsPDF();
      const pdf_height = doc.internal.pageSize.height;
      const pdf_width = doc.internal.pageSize.width;

      doc.setFont("Amiri");

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db)
          var imgSrc = arrayOfImages[i].logo;
      }

      const schoolDetails = this.user.building.filter(
        (el) => el.dbName == this.settings.activeBuilding
      )[0];

      const schoolName = schoolDetails.ArabicName || schoolDetails.name;

      let imgData = "data:image/jpeg;base64, ";

      await axios
        .get(imgSrc, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          imgData += Buffer.from(response.data, "binary").toString("base64");
        });

      const data = {};
      let logo = "data:image/jpeg;base64, ";

      await axios
        .get("favicon.png", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          logo += Buffer.from(response.data, "binary").toString("base64");
        });

      let defaultStudentPhoto = "data:image/jpeg;base64, ";

      await axios
        .get("/resources/images/blank.png", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          defaultStudentPhoto += Buffer.from(response.data, "binary").toString(
            "base64"
          );
        });

      this.filtredTable.forEach((student) => {
        if (!data[student.classRoom]) data[student.classRoom] = [];
        data[student.classRoom].push(student);
      });

      let y = 0;
      let left = true;
      let firstPage = true;
      let currentPage = 1;

      // array for optimization
      const promises = [];

      for (const classroom of Object.keys(data)) {
        doc.setFontSize(17);
        y = 0;
        if (!firstPage) {
          doc.addPage();
          currentPage++;
          left = true;
        }
        const classroomData = data[classroom];
        for (let i = 0; i < classroomData.length; i++) {
          if (left)
            promises.push(
              this.drawNewBadge(
                doc,
                0,
                y,
                imgData,
                logo,
                classroomData[i],
                currentPage,
                schoolName,
                defaultStudentPhoto,
                classroom
              )
            );
          else
            promises.push(
              this.drawNewBadge(
                doc,
                pdf_width / 2,
                y,
                imgData,
                logo,
                classroomData[i],
                currentPage,
                schoolName,
                defaultStudentPhoto,
                classroom
              )
            );

          if (!left) {
            y += pdf_height / 2;
            if (y + 2 >= pdf_height) {
              y = 0;
              left = false;
              doc.addPage();
              currentPage++;
            }
          }

          left = !left;
        }

        firstPage = false;
      }

      await Promise.all(promises)
        .then((values) => {
          this.loadingBadges = false;
          doc.save("Liste des badges.pdf");
        })
        .catch((reason) => {
          console.log(reason);
        });
      const end = performance.now();
      this.$gtag.event("Imprimer les badges avec photos", {
        event_category: "PRINT",
        print_what: "Liste des élèves section",
        duration: end - start,
      });
    },
    generatePdf() {
      const start = performance.now();
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Liste des élèves";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 65, titre);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(
          this.filtredTable[i].uniqueId ? this.filtredTable[i].uniqueId : "--"
        );

        arr.push(this.filtredTable[i].firstName);
        arr.push(this.filtredTable[i].lastName);
        arr.push(this.filtredTable[i].scolarite);
        arr.push(this.filtredTable[i].adress);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        margin: { top: 70 },
        head: [["Identifiant unique", "Prénon", "Nom", "scolarité", "Adress"]],
        body: array,
        foot: [
          [
            { colSpan: 3, content: "Total", styles: { halign: "center" } },
            {
              colSpan: 2,
              content: this.filtredTable.length,
              styles: { halign: "center" },
            },
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Liste_des_élèves.pdf");
      const end = performance.now();
      this.$gtag.event("Imp tab listeElèves", {
        event_category: "PRINT",
        event_label: "Liste des élèves section",
        duration: end - start,
      });
    },
    attestationInscriptionPDF(record) {
      const start = performance.now();
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      if (record) {
        var titre = "Attestation d'inscription";
        var string = `Je soussigné  ${
          this.activeBuilding.headMaster
        } le directeur de l'école ${
          this.activeBuilding.name
        }  atteste \n \nque l'élève ${
          record.firstName + " " + record.lastName
        } né(e) le ${
          record.BirthDate
        }  est insécrit(e) au sein \n \nde notre etablissement pour l'année scolaire ${
          this.currentYear
        }.`;
        var signature = "Signature";
        doc.setFontSize(30);
        doc.text(titre, 55, 90);
        doc.setFontSize(15);
        doc.text(string, 20, 110);
        doc.setFontSize(15);
        doc.text(signature, 150, 165);
      }
      doc.save("attestation d'inscription.pdf");
      const end = performance.now();
      this.$gtag.event("Imp attestation d'inscription", {
        event_category: "PRINT",
        event_label: "Liste des élèves section",
        duration: end - start,
      });
    },
    //arabe
    async attestationPresencePDF() {
      const start = performance.now();
      this.loadingData = true;
      this.visibleAttestationPresence = false;

      const pdfData = {
        commissariat: $("#cr").val(),
        nomStudent: $("#nomStudent").val(),
        BirthDate: moment(this.attestationPresenceBirthdate).format(
          "YYYY/MM/DD"
        ),
        className: $("#className").val(),
        reason: $("#reason").val(),
        recuEn: $("#recuEn").val(),
        headMaster: $("#dir").val(),
      };

      let stamp = "";
      await apiClient
        .get("/buildings/" + this.settings.activeBuildingId + "/stamp")
        .then((res) => {
          stamp = this.settings.base_url + "/" + res.data.imageURL;
        })
        .catch((e) => {
          console.log(e);
        });

      const stampImage = new Image();
      if (stamp) stampImage.src = stamp;

      let arrayOfImages = this.settings.image;
      let imgData;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
          break;
        }
      }
      const generateArcEnCielPDF = async () => {
        const doc = new JsPDF();
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = doc.internal.pageSize.getHeight();
        doc.setFont("Amiri");

        doc.setDrawColor(135, 206, 235);
        doc.setLineWidth(2);
        doc.setLineDashPattern([5, 3], 0);
        doc.rect(10, 10, pdfWidth - 20, pdfHeight - 20);

        let date = new Date();

        const t1 = "الجمهورية التونسية";
        const t2 = "وزارة التربية";
        const t3 = "المندوبية الجهوية بسليانة";
        const t4 = pdfData.commissariat;
        const t5 = "المدرسة الابتدائية الخاصة";
        let width = doc.getTextWidth(t1);
        let nwidth = 0;
        doc.text(t1, pdfWidth - 60 + (25 - width / 2), 25);
        width = doc.getTextWidth(t2);
        doc.text(t2, pdfWidth - 60 + (25 - width / 2), 35);
        width = doc.getTextWidth(t3);
        doc.text(t3, pdfWidth - 60 + (25 - width / 2), 45);
        width = doc.getTextWidth(t4);
        doc.text(t4, pdfWidth - 60 + (25 - width / 2), 55);
        width = doc.getTextWidth(t5);
        doc.text(t5, 80 - (40 + width / 2), 25);
        width = doc.getTextWidth(this.schoolDetails.ArabicName);
        doc.text(this.schoolDetails.ArabicName, 80 - (45 + width / 2), 35);
        const flagImage = new Image();
        flagImage.src = "/resources/images/flag.jpg";

        const govImage = new Image();
        govImage.src = "/resources/images/gov.jpg";
        if (imgData) {
          doc.addImage(imgData, "JPEG", 68, 20, 18, 18);
          doc.addImage(flagImage, "JPEG", 100, 20, 18, 18);
          doc.addImage(govImage, "JPEG", 130, 20, 18, 18);
        }

        doc.setFontSize(12);
        const titre = "شهادة حضور";
        const school = " : يشهد مدير المدرسة الإبتدائية الخاصة قوس قزح بسليانة";
        const student = " : (أن التلميذ )ة";
        const birth = pdfData.BirthDate + " : المولود )ة( في";
        const inscriIn = `(مرسّم )ة( بالمدرسة الإبتدائية الخاصة قوس قزح و يزاول دراسته )ها`;
        const inscriIn2 = `ب`;
        const reasonTitle = ": سلّمت هذه الشهادة للمعني بالأمر )ة( قصد";
        const reason = pdfData.reason;

        date = new Date().toISOString().split("T")[0].replace(/\-/g, "/");
        const signedIn = `${date} : حرّر بسليانة في `;

        const signature = "الإمضاء و الختم";
        const headmasterSignature = "(المدير )ة";
        const headmasterName = pdfData.headMaster;

        doc.setFontSize(30);
        width = doc.getTextWidth(titre);
        doc.text(titre, pdfWidth / 2 - width / 2, 90);
        doc.setFontSize(15);

        width = doc.getTextWidth(school);
        doc.text(school, pdfWidth - width - 20, 110);

        nwidth = doc.getTextWidth(pdfData.headMaster);
        doc.text(pdfData.headMaster, pdfWidth - width - nwidth - 20, 110);

        width = doc.getTextWidth(student);
        doc.text(student, pdfWidth - width - 20, 110 + 10);

        nwidth = doc.getTextWidth(pdfData.nomStudent);
        doc.text(pdfData.nomStudent, pdfWidth - width - nwidth - 20, 110 + 10);

        width = doc.getTextWidth(birth);
        doc.text(birth, pdfWidth - width - 20, 110 + 20);

        width = doc.getTextWidth(inscriIn);
        doc.text(inscriIn, pdfWidth - width - 20, 110 + 30);
        width = doc.getTextWidth(inscriIn2);
        doc.text(inscriIn2, pdfWidth - width - 20, 110 + 40);

        nwidth = doc.getTextWidth(pdfData.className);
        doc.text(pdfData.className, pdfWidth - width - nwidth - 20, 110 + 40);

        width = doc.getTextWidth(reasonTitle);
        doc.text(reasonTitle, pdfWidth - width - 20, 110 + 50);
        doc.setFontSize(13);
        width = doc.getTextWidth(reason);
        doc.text(reason, pdfWidth - width - 26, 110 + 60);
        doc.setFontSize(15);
        width = doc.getTextWidth(signedIn);
        doc.text(signedIn, pdfWidth - width - 90, 200);

        width = doc.getTextWidth(signature);
        doc.text(signature, pdfWidth - width - 40, 210);

        width = doc.getTextWidth(headmasterSignature);
        doc.text(headmasterSignature, pdfWidth - width - 40, 220);

        width = doc.getTextWidth(headmasterName);
        doc.text(headmasterName, pdfWidth - width - 40, 230);

        if (stamp && this.showStamp) {
          doc.addImage(stampImage, "JPEG", pdfWidth / 2 + 25, 230, 40, 40);
        }

        doc.save(`attestation_présence.pdf`);
      };
      const generateDefaultPDF = async () => {
        let serialNumber;
        try {
          const response = await apiClient.post("/printNumber", {
            type: "attPres",
            student: this.activeStudent._id,
          });
          serialNumber = response.data.serialNumber;
        } catch (error) {
          console.error("Error fetching serial number:", error);
        }
        const doc = new JsPDF("l", "mm", "a4");
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = doc.internal.pageSize.getHeight();

        const drawMixedText = (
          x,
          y,
          text,
          baseFontSize,
          targetRegex,
          smallReduction
        ) => {
          let parts = [];
          let lastIndex = 0;
          let regex = new RegExp(targetRegex, "g");
          let match;
          while ((match = regex.exec(text)) !== null) {
            if (match.index > lastIndex) {
              parts.push({
                text: text.slice(lastIndex, match.index),
                small: false,
              });
            }
            parts.push({ text: match[0], small: true });
            lastIndex = match.index + match[0].length;
          }
          if (lastIndex < text.length) {
            parts.push({ text: text.slice(lastIndex), small: false });
          }

          let currentX = x;
          parts.forEach((part) => {
            if (part.small) {
              doc.setFontSize(baseFontSize - smallReduction);
            } else {
              doc.setFontSize(baseFontSize);
            }
            doc.text(part.text, currentX, y);
            currentX += doc.getTextWidth(part.text);
          });
          doc.setFontSize(baseFontSize);
        };

        const getMixedTextWidth = (
          text,
          baseFontSize,
          targetRegex,
          smallReduction
        ) => {
          let totalWidth = 0;
          let lastIndex = 0;
          let regex = new RegExp(targetRegex, "g");
          let match;
          while ((match = regex.exec(text)) !== null) {
            if (match.index > lastIndex) {
              doc.setFontSize(baseFontSize);
              totalWidth += doc.getTextWidth(
                text.slice(lastIndex, match.index)
              );
            }
            doc.setFontSize(baseFontSize - smallReduction);
            totalWidth += doc.getTextWidth(match[0]);
            lastIndex = match.index + match[0].length;
          }
          if (lastIndex < text.length) {
            doc.setFontSize(baseFontSize);
            totalWidth += doc.getTextWidth(text.slice(lastIndex));
          }
          return totalWidth;
        };

        const createDocumentHalf = (xOffset, isRightHalf = false) => {
          doc.setFont("Amiri");
          let date = new Date();
          const halfWidth = pdfWidth / 2;
          const scaleFactor = 0.8;
          const imgWidth = 15 * scaleFactor;
          const imgHeight = 20 * scaleFactor;
          const imgMargin = 1;
          const imgX = isRightHalf
            ? halfWidth - imgWidth - imgMargin
            : pdfWidth - imgWidth - imgMargin;
          const image = new Image();
          image.src = "/resources/images/print.png";
          const govImage = new Image();
          govImage.src = "/resources/images/gov.jpg";
          doc.addImage(
            image,
            "PNG",
            imgX,
            pdfHeight / 2 - imgHeight / 2,
            imgWidth,
            imgHeight
          );
          const govImgWidth = 25;
          const govImgX = isRightHalf
            ? pdfWidth / 2 + (pdfWidth / 2 - govImgWidth) / 2 - 7
            : (pdfWidth / 2 - govImgWidth) / 2 - 7;
          doc.addImage(govImage, "JPEG", govImgX, 2, govImgWidth, 22);
          const contentMargin = imgWidth + imgMargin + 5;
          const textAlignRight = isRightHalf
            ? pdfWidth - contentMargin
            : halfWidth - contentMargin;
          const headerTexts = [
            "الجمهورية التونسية",
            "وزارة التربية",
            "المندوبية الجهوية للتربية",
            pdfData.commissariat,
          ];
          doc.setFontSize(8);
          headerTexts.forEach((text, index) => {
            doc.text(
              text,
              textAlignRight - doc.getTextWidth(text),
              6 + index * 5
            );
          });
          if (imgData) {
            const imgMargin = 4;
            const imgX = isRightHalf ? halfWidth + imgMargin : imgMargin;
            doc.addImage(imgData, "JPEG", imgX, 2, 25, 25);
          }
          const centerX = isRightHalf
            ? pdfWidth - halfWidth / 2
            : halfWidth / 2;
          doc.setFontSize(18);
          const titre = "شهادة حضور";
          const titleWidth = doc.getTextWidth(titre);
          const titleX = centerX - titleWidth / 2;
          doc.text(titre, titleX, 65);
          doc.setTextColor(255, 0, 0);
          doc.setFontSize(10);
          const serialText = `N° ${serialNumber}`;
          const serialX = titleX - doc.getTextWidth(serialText) - 5;
          doc.text(serialText, serialX, 65);
          doc.setTextColor(0, 0, 0);
          doc.setFontSize(10);

          const detailsMap = [
            {
              parts: [
                { text: " المدرسة الإبتدائية الخاصّة", small: false },
                { text: "(1)", small: true, reduction: 3 },
                { text: " : ", small: false },
                { text: "يشهد مدير", small: false },
              ].reverse(),
              value: this.schoolDetails.ArabicName,
            },
            { label: " : (أن التلميذ )ة", value: pdfData.nomStudent },
            { label: " : المولود )ة( في", value: pdfData.BirthDate },
            {
              label:
                "(مرسّم )ة( بالمدرسة */ المعهد المذكور )ة(* أعلاه و يزاول دراسته )ها",
              value: "",
            },
            { label: "(2)ب", value: pdfData.className },
          ];

          const baseFontSizeLabels = 10;
          detailsMap.forEach((detail, index) => {
            const yPosition = 80 + index * 7;

            if (detail.parts) {
              let totalWidth = 0;
              detail.parts.forEach((part) => {
                doc.setFontSize(
                  part.small
                    ? baseFontSizeLabels - (part.reduction || 2)
                    : baseFontSizeLabels
                );
                totalWidth += doc.getTextWidth(part.text);
              });

              let currentX = textAlignRight;
              detail.parts.forEach((part) => {
                doc.setFontSize(
                  part.small
                    ? baseFontSizeLabels - (part.reduction || 2)
                    : baseFontSizeLabels
                );
                currentX -= doc.getTextWidth(part.text);
                doc.text(part.text, currentX, yPosition);
              });

              if (detail.value) {
                doc.setFontSize(baseFontSizeLabels);
                doc.text(
                  detail.value,
                  textAlignRight -
                    totalWidth -
                    doc.getTextWidth(detail.value) -
                    2,
                  yPosition
                );
              }
            } else {
              const totalWidth = getMixedTextWidth(
                detail.label,
                baseFontSizeLabels,
                "\\([12]\\)",
                2
              );
              const labelX = textAlignRight - totalWidth;
              drawMixedText(
                labelX,
                yPosition,
                detail.label,
                baseFontSizeLabels,
                "\\([12]\\)",
                3
              );
              if (detail.value) {
                const labelPartWidth = getMixedTextWidth(
                  detail.label,
                  baseFontSizeLabels,
                  "\\([12]\\)",
                  3
                );
                doc.text(
                  detail.value,
                  textAlignRight -
                    labelPartWidth -
                    doc.getTextWidth(detail.value) -
                    2,
                  yPosition
                );
              }
            }
          });

          doc.setFontSize(10);
          const reasonTitle = ": سلّمت هذه الشهادة للمعني بالأمر )ة( قصد";
          doc.text(
            reasonTitle,
            textAlignRight - doc.getTextWidth(reasonTitle),
            118
          );
          doc.setFontSize(9);
          doc.text(
            pdfData.reason,
            textAlignRight - doc.getTextWidth(pdfData.reason) - 2,
            124
          );
          date = new Date().toISOString().split("T")[0].replace(/\-/g, "/");
          const signedIn = `${date} حرّر بـ ${pdfData.recuEn} في`;
          const signatureDetails = [
            signedIn,
            "الإمضاء و الختم",
            "(3) (المدير )ة",
            pdfData.headMaster,
          ];
          doc.setFontSize(9);
          const signatureX = isRightHalf ? pdfWidth - halfWidth + 40 : 40;
          signatureDetails.forEach((text, index) => {
            if (index === 2) {
              const totalWidth = getMixedTextWidth(text, 9, "\\(3\\)", 3);
              const startX = signatureX - totalWidth / 2;
              drawMixedText(startX, 145 + index * 6, text, 9, "\\(3\\)", 3);
            } else {
              doc.text(
                text,
                signatureX - doc.getTextWidth(text) / 2,
                145 + index * 6
              );
            }
          });
          doc.setFontSize(7);
          const indications = [
            { number: "", text: "____________________" },
            { number: "(1)", text: "بيان إسم المؤسسة التربوية" },
            { number: "(2)", text: "يذكر الفصل بلسان القلم" },
            { number: "(3)", text: "يذكر الإسم و اللقب و الصفة" },
            { number: "(*)", text: "يشطب الزائد" },
          ];
          doc.setFontSize(7);
          indications.forEach((indication, index) => {
            const textPosition = textAlignRight - 40;
            const numberPosition =
              textPosition + doc.getTextWidth(indication.text);
            doc.text(indication.text, textPosition, 170 + index * 4);
            doc.text(indication.number, numberPosition, 170 + index * 4);
          });
          if (stamp && this.showStamp) {
            const stampX = isRightHalf ? pdfWidth - halfWidth + 28 : 28;
            doc.addImage(stampImage, "JPEG", stampX, 165, 25, 25);
          }
        };

        createDocumentHalf(0, false);
        createDocumentHalf(0, true);
        doc.save("attestation_présence.pdf");
      };

      try {
        if (this.schoolAccessTemplateID === "AC") {
          await generateArcEnCielPDF();
        } else {
          await generateDefaultPDF();
        }
      } catch (error) {
        console.error("Error generating PDFs:", error);
      } finally {
        this.loadingData = false;
      }
    },
    async attestationPresenceFr(record) {
      const start = performance.now();
      this.loadingData = true;

      this.visibleAttestationPresence = false;
      const pdfData = {
        commissariat: $("#cr").val(),
        nomStudent: $("#nomStudent").val(),
        BirthDate: moment(this.attestationPresenceBirthdate).format(
          "DD/MM/YYYY"
        ),
        className: $("#className").val(),
        reason: $("#reason").val(),
        recuEn: $("#recuEn").val(),
        headMaster: $("#dir").val(),
      };

      let stamp = "";
      await apiClient
        .get("/buildings/" + this.settings.activeBuildingId + "/stamp")
        .then((res) => {
          stamp = this.settings.base_url + "/" + res.data.imageURL;
        })
        .catch((e) => {
          console.log(e);
        });
      const stampImage = new Image();
      if (stamp) stampImage.src = stamp;

      let arrayOfImages = this.settings.image;
      let imgData;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
          break;
        }
      }

      const doc = new JsPDF();
      const pdfWidth = doc.internal.pageSize.getWidth();
      doc.setFont("Amiri");
      var date = new Date();

      const t1 = "République Tunisienne";
      const t2 = "Ministère de l'Education";
      const t3 = "Commissariat Régional de l'Education";
      const t4 = pdfData.commissariat;
      const t5 = "Ecole Primaire";

      doc.text(t1, 10, 10, { align: "left" });
      doc.text(t2, 10, 18, { align: "left" });
      doc.text(t3, 10, 26, { align: "left" });
      doc.text(t4, 10, 34, { align: "left" });

      doc.text(t5, pdfWidth - 10, 10, { align: "right" });
      doc.text(this.schoolDetails.name, pdfWidth - 10, 18, { align: "right" });
      const schoolNameWidth = doc.getTextWidth(this.schoolDetails.name);
      const imageWidth = 22;
      const imageX = pdfWidth - 10 - schoolNameWidth / 2 - imageWidth / 2;
      doc.addImage(imgData, "JPEG", imageX, 25, imageWidth, imageWidth);

      doc.setFontSize(12);
      date = new Date().toLocaleDateString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      const signedIn = `Fait à ${pdfData.recuEn}, le ${date}`;
      const signature = "Signature et Cachet";
      const headmasterSignature = "Le Directeur";
      doc.setFontSize(15);
      const rightMargin = pdfWidth - 20;

      if (record) {
        var titre = "Attestation de présence";
        var string = `Je soussigné M. ${pdfData.headMaster}  le directeur de l'école ${this.schoolDetails.name}  \n \n atteste que l'élève ${pdfData.nomStudent} né(e) le ${pdfData.BirthDate} est présent(e) \n \n au sein de notre etablissement pour l'année scolaire ${this.currentYear}.`;

        doc.setFontSize(30);
        doc.text(titre, 55, 90);
        doc.setFontSize(15);
        doc.text(string, 20, 110);
        doc.setFontSize(15);

        doc.text(signedIn, rightMargin, 165, { align: "right" });
        const signedInWidth = doc.getTextWidth(signedIn);
        const signatureX = rightMargin - signedInWidth / 2;
        doc.text(signature, signatureX, 175, { align: "center" });
        doc.text(headmasterSignature, signatureX, 185, { align: "center" });
      }
      if (stamp && this.showStamp) {
        try {
          await this.waitLoadingImage(stampImage);
          const signedInWidth = doc.getTextWidth(signedIn);
          const signatureX = rightMargin - signedInWidth / 2;
          const stampX = signatureX - 20;
          doc.addImage(stampImage, "JPEG", stampX, 195, 40, 40);
        } catch (e) {}
      }

      this.loadingData = false;
      doc.save("attestation_présence.pdf");
      const end = performance.now();
      this.$gtag.event("Imp fr attestation de présence", {
        event_category: "PRINT",
        event_label: "Liste des élèves section",
        duration: end - start,
      });
    },
    waitLoadingImage(image) {
      return new Promise((res, rej) => {
        image.onload = (t) => {
          res();
        };
        image.onerror = (e) => {
          rej();
        };
      });
    },

    attestationReussitePDF(record) {
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Attestation de réussite";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(30);
      doc.text(titre, 55, 90);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      if (record) {
        var titre = "Attestation de réussite";
        var string = `Je soussigné ${
          this.activeBuilding.headMaster
        } le directeur de l'école ${
          this.activeBuilding.dbName
        }  \n \n atteste que l'élève ${
          record.firstName + " " + record.lastName
        } né(e) le ${
          record.BirthDate
        } est présent(e) \n \n au sein de notre etablissement pour l'année scolaire ${
          this.currentYear
        }.`;
        var signature = "Signature";
        doc.setFontSize(30);
        doc.text(titre, 55, 90);
        doc.setFontSize(15);
        doc.text(string, 20, 110);
        doc.setFontSize(15);
        doc.text(signature, 150, 165);
      }
      doc.save("attestation_réussite.pdf");
    },
    showModal() {
      this.visibleData = true;
      this.newStudentForm.resetFields();
    },
    onChange(e) {
      this.newStudent.gender = e.target.value;
    },
    onChangeParent(e) {},
    openNotificationWithIcon(type, msg, desc) {
      notification[type]({
        message: msg,
        description: desc,
      });
    },
    handleParentsSuggestions(val) {
      if (this.suggestionTimeout) clearTimeout(this.suggestionTimeout);

      let parentsList = [];

      if (val.target.value) {
        parentsList = this.parentsList.filter(
          (e) =>
            e.fatherPhone == val.target.value ||
            e.motherPhone == val.target.value
        );
      }

      if (parentsList.length > 0) {
        this.suggestionTimeout = setTimeout(() => {
          this.suggestionParentList = parentsList;
          this.selectedSuggestionParent = this.suggestionParentList[0];
          this.visibleParentSuggestion = true;
        }, 700);
      } else {
        this.visibleParentSuggestion = false;
      }
    },
    handleAddParentSuggestion() {
      this.newStudentForm.setFieldsValue({
        parent: this.selectedSuggestionParent.id,
      });
      this.visibleParentSuggestion = false;
      clearTimeout(this.suggestionTimeout);
      setTimeout(() => {
        this.disabledSP = !this.disabledSP;
        this.newParent = !this.newParent;
      }, 100);
    },
    reload(data) {
      this.$emit("reload");
    },

    async updateParentAndPersonalInfo() {
      try {
        this.loadingData = true;
        const parentId = this.activeParent._id;
        const studentId = this.activeStudent;

        const getFNameLName = (fullName) => {
          const parts = fullName.split(" ");
          return parts.length > 1
            ? { firstName: parts[0], lastName: parts.slice(1).join(" ") }
            : { firstName: fullName, lastName: "" };
        };

        const parentUpdate = {};

        if (this.fichePersonnelData.fatherName) {
          const fatherNames = getFNameLName(this.fichePersonnelData.fatherName);
          parentUpdate.father = {
            firstName: fatherNames.firstName,
            lastName: fatherNames.lastName,
          };
          if (this.fichePersonnelData.fatherJob) {
            parentUpdate.father.job = this.fichePersonnelData.fatherJob;
          }
        }

        if (this.fichePersonnelData.motherName) {
          const motherNames = getFNameLName(this.fichePersonnelData.motherName);
          parentUpdate.mother = {
            firstName: motherNames.firstName,
            lastName: motherNames.lastName,
          };
          if (this.fichePersonnelData.motherJob) {
            parentUpdate.mother.job = this.fichePersonnelData.motherJob;
          }
        }

        if (this.fichePersonnelData.adress) {
          parentUpdate.adress = this.fichePersonnelData.adress;
        }

        if (this.fichePersonnelData.financialStatus) {
          parentUpdate.financialStatus =
            this.fichePersonnelData.financialStatus;
        }
        if (this.fichePersonnelData.numberOfChildren) {
          parentUpdate.numberOfChildren = parseInt(
            this.fichePersonnelData.numberOfChildren
          );
        }

        const studentUpdate = {};
        const studentNames = getFNameLName(this.fichePersonnelData.studentName);

        if (this.fichePersonnelData.studentName) {
          studentUpdate.firstName = studentNames.firstName;
          studentUpdate.lastName = studentNames.lastName;
        }

        if (this.fichePersonnelData.uniqueId) {
          studentUpdate.uniqueId = this.fichePersonnelData.uniqueId;
        }

        if (this.fichePersonnelData.birthDate) {
          studentUpdate.BirthDate = this.fichePersonnelData.birthDate;
        }

        if (this.fichePersonnelData.birthPlace) {
          studentUpdate.birthPlace = this.fichePersonnelData.birthPlace;
        }

        if (this.fichePersonnelData.notes) {
          studentUpdate.notes = this.fichePersonnelData.notes;
        }

        const personalInfo = {};

        if (this.fichePersonnelData.numberOfRegistrations) {
          personalInfo.numberOfRegistrations = parseInt(
            this.fichePersonnelData.numberOfRegistrations
          );
        }
        if (this.fichePersonnelData.entryDate) {
          personalInfo.entryDate = this.fichePersonnelData.entryDate;
        }
        if (this.fichePersonnelData.previousInstitution) {
          personalInfo.previousInstitution =
            this.fichePersonnelData.previousInstitution;
        }
        if (this.fichePersonnelData.exitDate) {
          personalInfo.exitDate = this.fichePersonnelData.exitDate;
        }
        if (this.fichePersonnelData.exitReason) {
          personalInfo.exitReason = this.fichePersonnelData.exitReason;
        }
        if (this.fichePersonnelData.secondarySchool) {
          personalInfo.secondarySchool =
            this.fichePersonnelData.secondarySchool;
        }
        if (this.fichePersonnelData.accompanyingDocuments) {
          personalInfo.accompanyingDocuments =
            this.fichePersonnelData.accompanyingDocuments;
        }
        if (this.fichePersonnelData.effort) {
          personalInfo.effort = this.fichePersonnelData.effort;
        }
        if (this.fichePersonnelData.behavior) {
          personalInfo.behavior = this.fichePersonnelData.behavior;
        }
        if (this.fichePersonnelData.attendance) {
          personalInfo.attendance = this.fichePersonnelData.attendance;
        }

        studentUpdate.personalInfo = personalInfo;

        const updatePromises = [];

        if (Object.keys(parentUpdate).length > 0) {
          updatePromises.push(
            apiClient.patch(`/parents/${parentId}`, { data: parentUpdate })
          );
        }

        if (Object.keys(studentUpdate).length > 0) {
          updatePromises.push(
            apiClient.patch(`/students/${studentId}`, { data: studentUpdate })
          );
        }

        if (updatePromises.length > 0) {
          const responses = await Promise.all(updatePromises);

          if (responses.every((response) => response.status === 200)) {
            this.$message.success(this.$t("success.eleveMSA"));
            this.visibleFichePersonnel = false;

            const fatherNames = getFNameLName(
              this.fichePersonnelData.fatherName
            );
            const motherNames = getFNameLName(
              this.fichePersonnelData.motherName
            );

            const pdfData = {
              includePhoto: this.fichePersonnelData.includePhoto,
              studentPhoto: this.fichePersonnelData.studentPhoto,
              firstName: studentNames.firstName,
              lastName: studentNames.lastName,
              father: {
                name: `${fatherNames.firstName} ${fatherNames.lastName}`,
                job: this.fichePersonnelData.fatherJob || "",
              },
              mother: {
                name: `${motherNames.firstName} ${motherNames.lastName}`,
                job: this.fichePersonnelData.motherJob || "",
              },
              BirthDate: this.fichePersonnelData.birthDate
                ? moment(this.fichePersonnelData.birthDate).format("YYYY/MM/DD")
                : "",
              birthPlace: this.fichePersonnelData.birthPlace || "",
              uniqueId: this.fichePersonnelData.uniqueId || "",
              personalInfo: {
                adress: this.fichePersonnelData.adress,
                numberOfRegistrations:
                  this.fichePersonnelData.numberOfRegistrations || "",
                financialStatus: this.fichePersonnelData.financialStatus || "",
                numberOfChildren:
                  this.fichePersonnelData.numberOfChildren || "",
                entryDate: this.fichePersonnelData.entryDate
                  ? moment(this.fichePersonnelData.entryDate).format(
                      "YYYY/MM/DD"
                    )
                  : "",
                exitDate: this.fichePersonnelData.exitDate
                  ? moment(this.fichePersonnelData.exitDate).format(
                      "YYYY/MM/DD"
                    )
                  : "",
                exitReason: this.fichePersonnelData.exitReason || "",
                previousInstitution:
                  this.fichePersonnelData.previousInstitution || "",
                secondarySchool: this.fichePersonnelData.secondarySchool || "",
                accompanyingDocuments:
                  this.fichePersonnelData.accompanyingDocuments || "",
                notes: this.fichePersonnelData.notes || "",
                effort: this.fichePersonnelData.effort || "",
                behavior: this.fichePersonnelData.behavior || "",
                attendance: this.fichePersonnelData.attendance || "",
              },
            };

            await this.generateFichePersonnelPDF(pdfData);
            await this.init();
          } else {
            throw new Error(this.$t("error.impoModifiEleve"));
          }
        }
      } catch (error) {
        this.$message.error(this.$t("error.impoModifiEleve"));
      } finally {
        this.loadingData = false;
      }
    },
    openFichePersonnel(record) {
      this.activeStudent = record.id;
      this.activeParent = record.parent[0];

      this.fichePersonnelData = {
        includePhoto: false,
        studentPhoto: record.photo,
        studentName: `${record.firstName} ${record.lastName}`,
        uniqueId: record.uniqueId || "",
        birthDate: moment(record.BirthDate, "MM/DD/YYYY"),
        birthPlace: record.birthPlace || "",

        fatherName: `${record.parent[0].father.firstName} ${record.parent[0].father.lastName}`,
        fatherJob: record.parent[0].father.job || "",

        motherName: `${record.parent[0].mother.firstName} ${record.parent[0].mother.lastName}`,
        motherJob: record.parent[0].mother.job || "",

        numberOfChildren: record.parent[0].numberOfChildren,
        adress: record.parent[0].adress || "",
        financialStatus: record.parent[0].financialStatus,

        numberOfRegistrations: record.personalInfo
          ? record.personalInfo.numberOfRegistrations
          : null,
        entryDate:
          record.personalInfo && record.personalInfo.entryDate
            ? moment(record.personalInfo.entryDate)
            : null,
        exitDate:
          record.personalInfo && record.personalInfo.exitDate
            ? moment(record.personalInfo.exitDate)
            : null,
        exitReason: record.personalInfo ? record.personalInfo.exitReason : "",
        previousInstitution: record.personalInfo
          ? record.personalInfo.previousInstitution
          : "",
        secondarySchool: record.personalInfo
          ? record.personalInfo.secondarySchool
          : "",
        accompanyingDocuments: record.personalInfo
          ? record.personalInfo.accompanyingDocuments
          : "",
        notes: record.notes || "",
        effort: record.personalInfo ? record.personalInfo.effort : "",
        behavior: record.personalInfo ? record.personalInfo.behavior : "",
        attendance: record.personalInfo ? record.personalInfo.attendance : "",
      };

      this.visibleFichePersonnel = true;
    },
    async generateFichePersonnelPDF(pdfData = null) {
      this.$gtag.event("Imp ar fiche personnel", {
        event_category: "Impression PDF",
        event_label: "Fiche personnel",
        value: 1,
      });
      this.loadingData = true;
      this.visibleFichePersonnel = false;
      if (!pdfData) {
        this.visibleFichePersonnel = false;
      }

      const doc = new JsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        hotfixes: ["px_scaling"],
      });

      doc.setFont("Amiri");

      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      const lineSpacing = 11;
      const valueOffset = 5;

      const loadBackgroundImage = () => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "Anonymous";
          img.onload = () => resolve(img);
          img.onerror = (err) => {
            console.error("Error loading background image:", err);
            reject(err);
          };
          img.src = "/resources/images/frame.png";
        });
      };

      try {
        const bgImg = await loadBackgroundImage();
        doc.addImage(bgImg, "JPEG", 0, 0, pdfWidth, pdfHeight);
      } catch (error) {
        console.error("Error adding background image to PDF:", error);
      }

      let yPos = 20;
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setDrawColor(0);
      doc.setLineWidth(0.5);

      doc.rect(15, yPos, 30, 15);
      const registrationNum = String(
        pdfData.personalInfo.numberOfRegistrations || ""
      );
      const registrationLabel = "عدد الترسيم";
      const regLabelWidth = doc.getTextWidth(registrationLabel);
      const regNumWidth = doc.getTextWidth(registrationNum);
      doc.text(registrationLabel, 15 + 15 - regLabelWidth / 2, yPos + 5);
      doc.text(registrationNum, 15 + 15 - regNumWidth / 2, yPos + 12);

      doc.setFontSize(32);
      const title = "مذكرة شخصية";
      let titleWidth = doc.getTextWidth(title);
      doc.text(title, pdfWidth / 2 - titleWidth / 2, yPos + 7);

      doc.setFontSize(22);
      const photoX = pdfWidth - 45;
      const photoY = yPos - 5;
      const photoWidth = 35;
      const photoHeight = 40;
      doc.rect(photoX, photoY, photoWidth, photoHeight);

      if (
        this.fichePersonnelData.includePhoto &&
        this.fichePersonnelData.studentPhoto
      ) {
        try {
          const studentPhotoURL = `${this.settings.base_url}/${this.fichePersonnelData.studentPhoto}`;
          const loadImage = () => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.crossOrigin = "Anonymous";
              img.onload = () => resolve(img);
              img.onerror = (err) => {
                console.error("Error loading image:", err);
                reject(err);
              };
              img.src = studentPhotoURL;
            });
          };

          const img = await loadImage();
          const imgAspectRatio = img.width / img.height;
          const rectAspectRatio = photoWidth / photoHeight;
          let finalWidth, finalHeight;
          if (imgAspectRatio > rectAspectRatio) {
            finalWidth = photoWidth - 2;
            finalHeight = finalWidth / imgAspectRatio;
          } else {
            finalHeight = photoHeight - 2;
            finalWidth = finalHeight * imgAspectRatio;
          }
          const xOffset = (photoWidth - finalWidth) / 2;
          const yOffset = (photoHeight - finalHeight) / 2;

          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.fillStyle = "#FFFFFF";
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);
          const imageData = canvas.toDataURL("image/jpeg");

          doc.addImage(
            imageData,
            "JPEG",
            photoX + xOffset,
            photoY + yOffset,
            finalWidth,
            finalHeight
          );
        } catch (error) {
          console.error("Error adding student photo to PDF:", error);
        }
      }

      yPos += 30;
      const schoolName = " المدرسة الإبتدائية الخاصّة";
      const arabicSchoolName = this.schoolDetails
        ? this.schoolDetails.ArabicName
        : "";
      doc.setFontSize(16);
      let schoolNameWidth = doc.getTextWidth(schoolName);
      doc.text(schoolName, pdfWidth / 2 - schoolNameWidth / 2, yPos);

      if (arabicSchoolName) {
        yPos += 7;
        schoolNameWidth = doc.getTextWidth(arabicSchoolName);
        doc.text(arabicSchoolName, pdfWidth / 2 - schoolNameWidth / 2, yPos);
      }

      yPos += 15;
      doc.setFontSize(12);

      const drawField = (
        xRight,
        columnWidth,
        label,
        value,
        y,
        isPaired = false,
        isSecondField = false
      ) => {
        const labelText = `: ${label}`;
        const labelWidth = doc.getTextWidth(labelText);

        doc.text(labelText, xRight, y, { align: "right" });

        const availableValueWidth = isPaired
          ? isSecondField
            ? columnWidth - labelWidth - valueOffset
            : columnWidth - labelWidth - valueOffset + 10
          : columnWidth - labelWidth - valueOffset;

        const valueStr = value != null ? String(value).trim() : "";

        if (valueStr) {
          if (isPaired) {
            if (doc.getTextWidth(valueStr) > availableValueWidth) {
              let truncatedText = valueStr;
              while (
                doc.getTextWidth(truncatedText + "...") > availableValueWidth &&
                truncatedText.length > 0
              ) {
                truncatedText = truncatedText.slice(0, -1);
              }
              truncatedText += "...";
              doc.text(truncatedText, xRight - labelWidth - valueOffset, y, {
                align: "right",
              });
            } else {
              doc.text(valueStr, xRight - labelWidth - valueOffset, y, {
                align: "right",
              });
            }
            return y;
          } else {
            // For single fields, allow multi-line text
            const splittedText = doc.splitTextToSize(
              valueStr,
              availableValueWidth
            );

            for (let i = 0; i < splittedText.length; i++) {
              const currentY = y + i * lineSpacing;
              doc.text(
                splittedText[i],
                xRight - labelWidth - valueOffset,
                currentY,
                { align: "right" }
              );
            }

            return y + (splittedText.length - 1) * lineSpacing;
          }
        } else {
          const dottedLineEnd = xRight - labelWidth - valueOffset;
          const dottedLineStart = dottedLineEnd - availableValueWidth;

          const leftMargin = 20;
          const adjustedDottedLineStart =
            dottedLineStart < leftMargin ? leftMargin : dottedLineStart;

          doc.setLineDashPattern([1, 1], 0);
          doc.line(adjustedDottedLineStart, y + 1, dottedLineEnd, y + 1);
          doc.setLineDashPattern([], 0);

          return y;
        }
      };

      const addFieldSingle = (label, value, y) => {
        const xRight = pdfWidth - 20;
        const columnWidth = pdfWidth - 40;
        return (
          drawField(xRight, columnWidth, label, value, y, false) + lineSpacing
        );
      };

      const addFieldPair = (labelA, valueA, labelB, valueB, y) => {
        const columnAWidth = (pdfWidth - 40) * 0.55;
        const columnBWidth = (pdfWidth - 40) * 0.45;

        const xRightA = pdfWidth - 20;
        const xRightB = pdfWidth / 2 - 10;
        const yA = drawField(
          xRightA,
          columnAWidth,
          labelA,
          valueA,
          y,
          true,
          false
        );
        const yB = drawField(
          xRightB,
          columnBWidth,
          labelB,
          valueB,
          y,
          true,
          true
        );

        return Math.max(yA, yB) + lineSpacing;
      };

      yPos = addFieldSingle("المعرف الوحيد", pdfData.uniqueId || "", yPos);

      yPos = addFieldPair(
        "الإسم",
        pdfData.firstName,
        "اللقب",
        pdfData.lastName,
        yPos
      );

      yPos = addFieldPair(
        "تاريخ الولادة",
        pdfData.BirthDate || "",
        "مكانها",
        pdfData.birthPlace || "",
        yPos
      );

      yPos = addFieldPair(
        "اسم الأب ولقبه",
        pdfData.father.name || "",
        "المهنة",
        pdfData.father.job || "",
        yPos
      );

      yPos = addFieldPair(
        "اسم الأم ولقبها",
        pdfData.mother.name || "",
        "المهنة",
        pdfData.mother.job || "",
        yPos
      );

      const personalFields = [
        { label: "عدد الأبناء", value: pdfData.personalInfo.numberOfChildren },
        {
          label: "الحالة المادية للأسرة",
          value: pdfData.personalInfo.financialStatus,
        },
        { label: "العنوان", value: pdfData.personalInfo.adress },
        { label: "تاريخ الدخول", value: pdfData.personalInfo.entryDate },
        {
          label: "متأتي روضة",
          value: pdfData.personalInfo.previousInstitution || "",
        },
        { label: "تاريخ الخروج", value: pdfData.personalInfo.exitDate },
        { label: "السبب", value: pdfData.personalInfo.exitReason },
        {
          label: "المعهد الثانوي الذي ارتقى إليه",
          value: pdfData.personalInfo.secondarySchool,
        },
        {
          label: "الوثائق المصاحبة",
          value: pdfData.personalInfo.accompanyingDocuments,
        },
        { label: "ملاحظات", value: pdfData.personalInfo.notes },
        { label: "الاجتهاد", value: pdfData.personalInfo.effort },
        { label: "السيرة", value: pdfData.personalInfo.behavior },
        { label: "المواظبة", value: pdfData.personalInfo.attendance },
      ];
      personalFields.forEach((field) => {
        yPos = addFieldSingle(field.label, field.value || "", yPos);
      });

      this.loadingData = false;

      doc.save("fiche_personnelle.pdf");
    },
  },
};
</script>
<style scoped>
.fiche-personnel-form {
  width: 100%;
}

.form-row {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  width: 100%;
}

.form-item {
  flex: 1;
  min-width: 0;
}

.form-item label {
  display: block;
  width: 100%;
}

.form-item .ant-input,
.form-item .ant-calendar-picker {
  width: 100%;
}

.ant-input,
.ant-calendar-picker {
  max-width: 100%;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.border-with-text {
  position: relative;
  border: 2px solid #95a3b3;
  padding: 1rem;
}

.border-with-text .border-text {
  position: absolute;
  top: -12px;
  left: 20px;
  background-color: white;
  padding: 0 10px;
  font-weight: bold;
}
::v-deep .ant-list-item {
  margin-bottom: 0px !important;
}
.custom-border {
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
  cursor: pointer;
}
.custom-border-active {
  border: 3px solid #22bb33;
  border-radius: 0.25rem;
  cursor: pointer;
}
</style>
